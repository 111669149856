import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom'
import { withSnackbar } from 'notistack';
import { Tooltip, Button, Breadcrumbs, TextField, Typography, Link, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Autocomplete } from '@material-ui/lab';
import { revAssetTypeValues } from '../../../constants'

const MoveDataModal = (props) => {
  const { moveData, selectedItems, toggleModalFn, getMoveFolders, getMoveBackFolders, getMoveFirstFolders, setMoveFolder, onMoveDmData, allAssets, changeAssetSwitcher, selectedAsset, getuuidParent } = props
  return (
    <Dialog
      open={moveData.modal}
      onClose={() => toggleModalFn(false)}
      aria-labelledby="scroll-dialog-title"
      fullWidth='true'
    >
      <DialogTitle id="scroll-dialog-title">

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          Move {moveData.moveType === 'single' ? moveData.name.length < 18 ? moveData.name : <Tooltip title={moveData.name} arrow><span>{moveData.name.slice(0, 18)} ...</span></Tooltip> : selectedItems.ids.length + ' Items'}
          <span style={{ width: '180px' }}>
            {changeAssetSwitcher}
          </span>
        </div>
      </DialogTitle>
      <DialogContent dividers={true}>
        <div className="move-data-modal">
          <Breadcrumbs maxItems={3} aria-label="breadcrumb">
            {moveData.navigation.map((nav, index) =>
              moveData.navigation.length - 1 == index ?
                  <Link  onClick={(e) => { setMoveFolder(nav.uuid); }} className="list-inline-item navigation_modal">{nav.name}</Link>
                :
                <Link onClick={(e) => {
                  getuuidParent(nav.uuid); setTimeout(() => {
                    getMoveFolders(nav.uuid)
                  }, 300)
                }} className="list-inline-item navigation_modal">{nav.name}</Link>
            )}
          </Breadcrumbs>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {moveData.moveType === 'single' ?
                <ul className="list-unstyled folder-list">
                  {moveData.list.map((item) =>
                    <li className={moveData.moveFolderuuid === item.uuid ? 'active' : ''} onClick={() => setMoveFolder(item.uuid)}>
                      {item.name}
                      <ArrowForwardIosIcon fontSize="small" onClick={(e) => {
                        e.preventDefault(); e.stopPropagation(); setTimeout(() => {
                          getMoveFolders(item.uuid);
                        }, 500); getuuidParent(item.uuid)
                      }} color="primary" style={{ float: 'right' }} />
                    </li>
                  )}
                </ul> :
                <ul className="list-unstyled folder-list">
                  {moveData.list.filter(moveItem => !selectedItems.ids.find(item => item.uuid === moveItem.uuid)).map((rowItem) =>
                    <li className={moveData.moveFolderuuid === rowItem.uuid ? 'active' : ''} onClick={() => setMoveFolder(rowItem.uuid)}>
                      {rowItem.name}
                      <ArrowForwardIosIcon fontSize="small" onClick={(e) => {
                        e.preventDefault(); e.stopPropagation(); setTimeout(() => {
                          getMoveFolders(rowItem.uuid);
                        }, 500); getuuidParent(rowItem.uuid)
                      }} color="primary" style={{ float: 'right' }} />
                    </li>
                  )}
                </ul>
              }
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => toggleModalFn(false)}>Cancel</Button>
        <Button disabled={!moveData.moveFolderuuid} onClick={onMoveDmData} variant="contained" color="primary">Move Here</Button>
      </DialogActions>
    </Dialog>
  )
}
export default withRouter(MoveDataModal);
